import React, { useMemo } from 'react';
import {
  Spacer,
  Menu,
  Box,
  Stack,
  TableNext,
  Link,
  Caption,
  Paragraph,
} from '@dentsu-ui/components';
import { useHistory } from 'react-router-dom';
import { chatTypes } from '../../../../libs/constants';

const ChatTable = (props: any) => {
  const {
    chatData,
    setEditModalOpen,
    setDeleteChatModalOpen,
    setSelectedChatData,
    setIdData,
  } = props;
  const history = useHistory();

  const menuOptions = (data) => (
    <Stack justifyContent="center">
      <Link
        onClick={() => {
          setIdData(data.id);
          history.push(`/copilot/${data.chatName.replaceAll(' ', '')}`);
        }}>
        View
      </Link>
      <Spacer width={10} />
      <Menu>
        <Menu.Button />
        <Menu.List>
          <Menu.Item
            onClick={() => {
              setSelectedChatData(data);
              setEditModalOpen(true);
            }}>
            Rename
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setSelectedChatData(data);
              setDeleteChatModalOpen(true);
            }}>
            Delete
          </Menu.Item>
        </Menu.List>
      </Menu>
    </Stack>
  );

  const renderChatTypes = (data) => (
    <Box>
      <Paragraph>{chatTypes[data.chatType]}</Paragraph>
      {data.segmentType ? (
        <Caption isSecondary>{data.segmentName}</Caption>
      ) : null}
    </Box>
  );

  const renderChatName = (data) => (
    <Box>
      <Paragraph>{data.chatName}</Paragraph>
      <Caption isSecondary>{data.askQueries[0].question}</Caption>
    </Box>
  );

  const chatTableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'chatName',
        accessor: 'chatName',
        allowSort: true,
        type: 'custom',
        Cell: ({ row }) => renderChatName(row.original),
      },
      {
        Header: 'Chat type',
        id: 'chatType',
        accessor: 'chatType',
        allowSort: true,
        type: 'custom',
        Cell: ({ row }) => renderChatTypes(row.original.chatDetails),
      },
      {
        Header: 'Last updated',
        id: 'lastUpdated',
        allowSort: true,
        accessor: 'lastUpdated',
      },
      {
        id: 'menu',
        accessor: 'number',
        type: 'menu',
        Cell: ({ row }) => menuOptions(row.original),
      },
    ],
    [],
  );
  return (
    <TableNext
      style={{ backgroundColor: 'white' }}
      columns={chatTableColumns}
      data={chatData}
      defaultPageSize={10}
      pageSizeOptions={[10, 20, 30, 50, 100]}
      hasTotal
      // isExpandable
      // isFilterable
      isSearchable
      isSortable
      hasPagination
      // filters={selectedFilters}
      // search={selectedSearch}
      isSelectable
      // selectedRows={selectedRows}
      // onSearchChange={setSelectedSearch}
      // onFiltersChange={setSelectedFilters}
      // onSelectedRowsChange={setSelectedRows}
    />
  );
};

export default ChatTable;
