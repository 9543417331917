import React from 'react';
import ReactDOM from 'react-dom';
import '@dentsu-ui/components/styles.css';
import Shell from './Shell';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Shell />,
  document.getElementById('dc-app'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
