const constants: any = {
  collectionName: '',
  DENTSU_INSIGHTS: 'dentsuInsights',
  MARKETING_INTELLIGENCE: 'marketingIntelligence',
};

const chatTypes: any = {
  dentsuInsights: 'Dentsu Insights',
  marketingIntelligence: 'Marketing Intelligence',
}

export { constants, chatTypes };
