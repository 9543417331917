import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Alert,
  Page,
  Stack,
  Caption,
  Divider,
  Spacer,
} from '@dentsu-ui/components';
import { useHistory } from 'react-router-dom';
import { IChatHeader } from '../../../../utils/interfaces';
import { chatTypes, constants } from '../../../../libs/constants';
import CreateNewChatModal from '../../../../components/CreateNewChatModal';
import DeleteChatModal from '../../../../components/DeleteChatModal';
import EditChatNameModal from '../../../../components/EditChatNameModal';

const CustomPage: any = styled(Page)`
  .bsVtLp {
    padding-left: 1rem;
  }
  padding: 28px 28px 0px 28px;
  box-shadow: 0px 2px 4px 0px rgba(5, 5, 30, 0.15);
`;

const CustomAlert: any = styled(Alert)`
  padding-left: 40px !important;
  min-height: 60px;
  border-color: var(--dentsu-color-r200) !important;
  .dentsu-alert__layout {
    max-width: initial !important;
  }
  .dentsu-alert__icon {
    margin-top: 17px !important;
  }
  .dentsu-alert__text {
    display: flex !important;
  }
`;

const ChatHeader: FC<IChatHeader> = ({
  error,
  chatId,
  chatType,
  chatName,
  segmentName,
  clearLoading,
  conversation,
  setChatState,
  searchLoading,
  clearTopicHistory,
}: IChatHeader) => {
  const history = useHistory();
  const [chatDBIndex, setChatDBIndex] = useState<number>(1);
  const [chatModalOpen, setNewChatModalOpen] = useState(false);
  const [deleteChatDialogOpen, setDeleteChatDialogOpen] =
    useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const dbIndex = Number(localStorage.getItem('currentDBIndex'));
    dbIndex ? setChatDBIndex(dbIndex) : setChatDBIndex(1);
  }, [clearLoading]);

  return (
    <>
      {error.length ? (
        <CustomAlert isBroadcast title="Chat limit reached" status="error">
          Create a new chat to continue.
        </CustomAlert>
      ) : null}
      {chatId ? (
        <CustomPage
          title={chatId === 0 ? `Chat ${chatDBIndex}` : chatName}
          metadata="Copilot AI"
          availableSpace={
            <Stack>
              <Caption as="span" isSecondary>
                Chat type:
              </Caption>
              <Spacer width={5} />
              <Caption as="span">{chatTypes[chatType]}</Caption>
              {chatType === constants.DENTSU_INSIGHTS && (
                <>
                  <Divider isFlexChild orientation="vertical" />
                  <Caption as="span" isSecondary>
                    Segment:
                  </Caption>
                  <Spacer width={5} />
                  <Caption as="span">{segmentName}</Caption>
                </>
              )}
            </Stack>
          }
          primaryAction={{
            variant: 'tertiary',
            content: 'Create new chat',
            onClick: () => {
              setNewChatModalOpen(true);
            },
            isDisabled:
              searchLoading || clearLoading || conversation.length === 0,
            icon: 'add',
          }}
          secondaryActions={[
            {
              content: 'Rename',
              onClick: () => setEditModalOpen(true),
            },
            {
              content: 'Delete',
              onClick: () => setDeleteChatDialogOpen(true),
            },
          ]}
        />
      ) : (
        <CustomPage
          title={chatId === 0 ? `Chat ${chatDBIndex}` : chatName}
          metadata="Copilot AI"
          availableSpace={
            <Stack>
              <Caption as="span" isSecondary>
                Chat type:
              </Caption>
              <Spacer width={5} />
              <Caption as="span">{chatTypes[chatType]}</Caption>
              {chatType === constants.DENTSU_INSIGHTS && (
                <>
                  <Divider isFlexChild orientation="vertical" />
                  <Caption as="span" isSecondary>
                    Segment:
                  </Caption>
                  <Spacer width={5} />
                  <Caption as="span">{segmentName}</Caption>
                </>
              )}
            </Stack>
          }
          primaryAction={{
            variant: 'primary',
            content: 'Create new chat',
            onClick: () => {
              setNewChatModalOpen(true);
            },
            isDisabled:
              searchLoading || clearLoading || conversation.length === 0,
            icon: 'add',
          }}
        />
      )}
      {chatModalOpen && (
        <CreateNewChatModal
          isNewChatModalOpen={chatModalOpen}
          clearTopicHistory={clearTopicHistory}
          setNewChatModalOpen={setNewChatModalOpen}
        />
      )}
      {editModalOpen && (
        <EditChatNameModal
          chatId={chatId}
          history={history}
          chatName={chatName}
          isOpen={editModalOpen}
          setChatState={setChatState}
          setModalOpen={setEditModalOpen}
        />
      )}
      {deleteChatDialogOpen && (
        <DeleteChatModal
          chatId={chatId}
          history={history}
          setChatState={setChatState}
          isOpen={deleteChatDialogOpen}
          setDialogOpen={setDeleteChatDialogOpen}
        />
      )}
    </>
  );
};

export default ChatHeader;
