const envVars = process.env;

const config = {
  env: 'production',
  apiUri: `${envVars.REACT_APP_MEDIA_AI_BACKEND_URI}`,
  okta: {
    issuer: envVars.REACT_APP_OKTA_ISSUER,
    redirect_uri: `${window.location.origin}/login/callback`,
    client_id: envVars.REACT_APP_CLIENT_ID,
  },
  appOrigin: envVars.REACT_APP_BASE_URL,
  isBypassSecurity: true,
  isEmbeddedApp: envVars.REACT_APP_IS_EMBEDDED_APP === 'true',
  cmsURL: envVars.REACT_APP_CMS_URL,
  appTitle: envVars.REACT_APP_NAME,
};

export default config;
