import React, { FC, useState } from 'react';
import {
  Modal,
  Button,
  Paragraph,
  Caption,
  Spacer,
  Stack,
  TextInput,
} from '@dentsu-ui/components';
import { IEditChatNameModal } from '../../utils/interfaces';
import { updateData } from '../../libs/IndexDB';

const EditChatNameModal: FC<IEditChatNameModal> = (props) => {
  const { isOpen, setModalOpen, setChatState, chatId, chatName, history } =
    props;
  const [newName, setNewName] = useState<string>(chatName);
  const handleEditChat = () => {
    if (chatId !== 0) {
      updateData(chatId, newName);
      setChatState([chatId]);
      setModalOpen(!isOpen);
      history &&
        history.push({
          pathname: `/copilot/${newName.replaceAll(' ', '')}`,
          state: { ...history.location.state, chatName: newName },
        });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={() => setModalOpen(!isOpen)}>
      <Modal.Header hasCloseButton title="Edit chat name" />
      <Modal.Body>
        <Stack flexDirection="column">
          <Paragraph>Chat name</Paragraph>
          <Spacer />
          <Caption as="span" isSecondary isItalic>
            Create a unique name using recognisable keywords
          </Caption>
          <Spacer height={10} />
          <TextInput
            value={newName}
            onChange={(e: {
              target: { value: React.SetStateAction<string> },
            }) => setNewName(e.target.value)}
          />
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleEditChat()}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditChatNameModal;
