import React from 'react';
import PlatformShell from '@gdo-global-client-reporting/dentsu-platform-shell';

import { constants } from './libs/constants';
import BridgeIntegration from './BridgeIntegration';
import config from './config';
import './App.css';

const Shell = () => {
  const cmsConfig = { collectionName: constants.collectionName };
  const { appTitle, isEmbeddedApp } = config;

  return (
    <PlatformShell
      cmsConfig={cmsConfig}
      showSideBar={false}
      isEmbeddedApp={isEmbeddedApp}
      appTitle={appTitle}
    >
      <BridgeIntegration />
    </PlatformShell>
  );
};

export default Shell;
