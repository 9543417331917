import React from 'react';
import { createBridge } from '@gdo-global-client-reporting/dentsu-platform-bridge';
import { useCMS } from '@gdo-global-client-reporting/dentsu-platform-shell';
// import { TagManager } from '@dentsu-ui/components';
import App from './App';
import config from './config';

const BridgeIntegration = () => {
  const { changeLanguage } = useCMS();
  const { appOrigin, isBypassSecurity } = config;

  createBridge({
    localeChangeHandler: (locale) => changeLanguage(locale),
    parentOrigin: appOrigin && appOrigin.trim(),
    isBypassSecurity,
    // debug, // trigger console debug messages
  });

  return <App />;
};

export default BridgeIntegration;
