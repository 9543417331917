import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Page, Layout, EmptyState, Paragraph,
} from '@dentsu-ui/components';
import { withRouter } from 'react-router-dom';

const NotFound = ({ history }) => (
  <Page
    title="Page not found"
  >
    <Layout>
      <Layout.Section>
        <Layout.Panel>
          <EmptyState
            layout="horizontal"
            size="medium"
            data-test="pageNotFound-emptyState"
            image="void"
            actions={{
              secondary: {
                label: 'Go back home',
                onClick: () => {
                  history.push('/');
                },
                isDisabled: false,
                isExternal: true,
                iconLeft: 'home',
                iconRight: null,
                isLoading: false,
              },
            }}
            title="Error 404"
          >
            <Paragraph>
              Sorry, page not found
            </Paragraph>
          </EmptyState>
        </Layout.Panel>
      </Layout.Section>
    </Layout>
  </Page>
);

NotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(NotFound);
