import React from 'react';
import { Stack, TextInput, Spacer, IconButton } from '@dentsu-ui/components';
import styled from 'styled-components';

const CustomStack = styled(Stack)`
  box-shadow: 0px -2px 4px 0px rgba(5, 5, 30, 0.15);
  display: flex;
  padding: 24px 134px;
  align-items: center;
  align-self: stretch;
  background: var(--neutral-n-350, #C6C6D0);
`;

const SearchBar = (props: any) => {
  const { error, question, setQuestion, getAIResponse, searchLoading, clearLoading } =
    props;

  return (
    <CustomStack>
      <TextInput
        isDisabled={searchLoading || error}
        isFullWidth
        placeholder="Search, or ask a question"
        value={question}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setQuestion(e.target.value)
        }
        onFocus={(e: { target: { select: () => any } }) => e.target.select()}
        onKeyPress={(e: { key: string }) =>
          e.key === 'Enter' && getAIResponse()
        }
      />
      <Spacer width="16px" />
      <IconButton
        isDisabled={error || searchLoading || clearLoading || question.length === 0}
        icon="chevron-right"
        onClick={() => getAIResponse()}
      />
    </CustomStack>
  );
};

export default SearchBar;
