import { AskResponse, ForecastRequest, ForecastResponse } from "./models";
import configs from '../config';

const API_URL = configs.apiUri;

const bearerToken = () => {
  const storage: any = localStorage.getItem('okta-token-storage') || {};
  const token = JSON.parse(storage);
  return `Bearer ${token?.accessToken?.accessToken}`;
};

// Below API is used for Dentsu Insights Chat Type
export async function chatApi(options: ForecastRequest): Promise<AskResponse> {
  const response = await fetch(`${API_URL}/chat`, {
    method: "POST",
    headers: {
      "Authorization": bearerToken(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(options)
  });

  const parsedResponse: AskResponse = await response.json();
  return parsedResponse;
}

export async function chatCCSApi(options: any): Promise<any> {
  const response = await fetch(`${API_URL}/ccs/chat`, {
    method: "POST",
    headers: {
      "Authorization": bearerToken(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(options)
  });

  const parsedResponse: AskResponse = await response.json();
  return parsedResponse;
}

// Below API is used for Marketting Intelligence Chat Type
export async function forecastApi(options: ForecastRequest): Promise<ForecastResponse> {
  const response = await fetch(`${API_URL}/forecast/modelapi`, {
    method: "POST",
    headers: {
      "Authorization": bearerToken(),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(options),
  });
  const parsedResponse = await response.text();
  const filterResponse: ForecastResponse = JSON.parse(parsedResponse.replace(/\bNaN\b/g, '0'));
  return filterResponse;
}

// Below API is used to clear Forecast history
export async function clearHistoryApi() {
  const response = await fetch(`${API_URL}/forecast/clearhistory`, {
    method: "POST",
    headers: {
      "Authorization": bearerToken(),
      "Content-Type": "application/json"
    },
  });
  return response;
}

// Below API is used to get the segment types for Dentsu Insights Chat Type
export async function ccsGetSegmentsApi() {
  const response = await fetch(`${API_URL}/ccs/segments`, {
    method: "GET",
    headers: {
      "Authorization": bearerToken(),
      "Content-Type": "application/json"
    },
  });
  const parsedResponse: any = await response.json();
  return parsedResponse;
}

// Below API is used to clear both Chat Types History i.e. Forecast and Chat
export async function clearMemoryApi() {
  const response = await fetch(`${API_URL}/memory`, {
    method: "DELETE",
    headers: {
      "Authorization": bearerToken(),
      "Content-Type": "application/json"
    },
  });
  return response;
}
