import React, { useEffect, FC } from 'react';
import { Navigation } from '@dentsu-ui/components';
import { NavLink, useHistory } from 'react-router-dom';
import { ISideNavigationProps } from '../../utils/interfaces';

const SideNavigation: FC<ISideNavigationProps> = ({
  indexDBData,
  doesChatExist,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (doesChatExist) {
      document?.getElementById('navigation-group-0')?.click();
    }
  }, [doesChatExist]);

  return (
    <Navigation as={NavLink}>
      <Navigation.Section
        style={{ display: 'flex' }}
        items={
          !doesChatExist
            ? [
                {
                  label: 'Copilot AI',
                  url: '/',
                  exact: true,
                  onClick: (e) => {
                    e.preventDefault();
                    history.push('/');
                  },
                },
              ]
            : [
                {
                  label: 'Copilot AI',
                  url: '/',
                  exact: true,
                  onClick: (e) => {
                    e.preventDefault();
                    history.push('/');
                  },
                  items: indexDBData,
                },
              ]
        }
      />
    </Navigation>
  );
};

export default SideNavigation;
