import moment from 'moment';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

const downloadXlsx = async (data) => {
  const filename = `dentsu chat export-[${moment().format('YYYYMMDD-HHMM')}]`;
  const storedData = localStorage.getItem('okta-token-storage');
  const workbook = new Excel.Workbook();
  const worksheet1 = workbook.addWorksheet('Summary');
  const worksheet2 = workbook.addWorksheet('Raw data');
  worksheet1.properties.defaultColWidth = 20;
  worksheet2.properties.defaultColWidth = 20;
  worksheet1.mergeCells('A1:L1');
  const headers = Object.keys(data?.forecast[0]);
  worksheet2.addRow(headers);
  data?.forecast.forEach((row) => {
    const dataRow: any = Object.values(row);
    dataRow[1] = moment(dataRow[1]).format('YYYY-MM-DD HH:mm:ss');
    worksheet2.addRow(dataRow);
  });
  const imageId2 = workbook.addImage({
    base64: data?.chart?.base64,
    extension: 'png',
  });
  const generated = worksheet1.getCell('B3');
  generated.font = {
    bold: true,
  };
  generated.value = 'Generated:';
  const dateCell = worksheet1.getCell('C3');
  dateCell.value = moment().format('DD/MM/YYYY HH:mm');
  const generatedBy = worksheet1.getCell('B4');
  generatedBy.font = {
    bold: true,
  };
  generatedBy.value = 'Generated By:';
  const userName = worksheet1.getCell('C4');
  userName.value = storedData ? JSON.parse(storedData).idToken.claims.name : '';
  const questionCell = worksheet1.getCell('B6');
  questionCell.font = {
    bold: true,
  };
  questionCell.value = 'Question:';
  const answerCell = worksheet1.getCell('C6');
  answerCell.value = data?.adjusted_question;
  const analystCell = worksheet1.getCell('B8');
  analystCell.font = {
    bold: true,
  };
  analystCell.value = 'AI analyst Summary:';
  const summaryCell = worksheet1.getCell('C8');
  summaryCell.value = data?.analysis;
  worksheet1.addImage(imageId2, 'A12:L60');
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileExtension = '.xlsx';
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension);
};

const formatDate = (date) => {
  const day = date.getDate();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;

  const formattedDate = `${day} ${month} ${year} ${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${ampm}`;

  return formattedDate;
};

export { downloadXlsx, formatDate };
