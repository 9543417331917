import React, { useEffect, useState, FC, useRef } from 'react';
import { Stack, Spacer, Toast, Scrollable } from '@dentsu-ui/components';
import { useHistory } from 'react-router-dom';
import { forecastApi, clearMemoryApi, chatCCSApi } from '../../api';
import { SearchBar, ChatLogs, ChatHeader } from './components';
import { getData, storeData } from '../../libs/IndexDB';
import { IChatInterfaceProps } from '../../utils/interfaces';
import { constants } from '../../libs/constants';
import { formatDate } from '../../helpers/helpers';

const ChatInterface: FC<IChatInterfaceProps> = ({
  chatState,
  setChatState,
  chatId,
  conversation,
  setConversation,
}: IChatInterfaceProps) => {
  const history: any = useHistory();

  // direct navigation to copilot API then return to homepage
  if (!history.location.state && chatId === 0) {
    history.push('/');
    return null;
  }

  const { DENTSU_INSIGHTS } = constants;
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [clearLoading, setClearLoading] = useState<boolean>(false);
  const [questionString, setQuestionString] = useState<string | null>();
  const [error, setError] = useState<any>('');
  const [searchText, setSearchText] = useState<string>('');
  const [selectedChatDetails, setSelectedChatDetails] = useState<any>({});
  const [selectedChatName, setSelectedChatName] = useState<string>('');
  const scrollRefContent = useRef<any>(null);
  const scrollRefLoader = useRef<any>(null);

  useEffect(() => {
    setError('');
    if (chatId !== 0) {
      getData(chatId).then((result) => {
        setSelectedChatDetails(result.chatDetails);
        setConversation(result.askQueries);
        setSelectedChatName(result.chatName);
      });
    } else {
      const {
        location: {
          state: { chatType },
        },
      } = history;
      setSelectedChatDetails({
        chatType,
        segmentName: history.location.state?.segmentValue?.label || '',
        segmentType: history.location.state?.segmentValue?.value || null,
      });
      setConversation([]);
    }
  }, [chatId, history.location.state]);

  const getAIResponse = async () => {
    // Below code is to close the Side Navigation manually
    const sideNavElement = document.querySelector('.dentsu-frame--isOpen');
    if (sideNavElement) {
      const elem: any = document.getElementsByClassName(
        'Framestyles__StyledFrameToggleButton-sc-ebhmw4-2 ',
      )[0];
      elem.click();
    }

    setSearchLoading(true);
    if (searchText !== '') {
      setQuestionString(searchText);
      try {
        let request: any = {
          question: searchText,
        };
        let result;
        if (selectedChatDetails.chatType === DENTSU_INSIGHTS) {
          request = {
            ...request,
            segment_name: selectedChatDetails.segmentType,
          };
          result = await chatCCSApi(request);
          if (result.error) throw result.error
        } else {
          result = await forecastApi(request);
        }
        result = {
          updatedAt: formatDate(new Date()),
          ...result,
        };
        setConversation([
          ...conversation,
          {
            question: searchText,
            answer: result,
          },
        ]);
      } catch (err) {
        if (selectedChatDetails.chatType === DENTSU_INSIGHTS) {
          setError(err);
        } else {
          setConversation([
            ...conversation,
            {
              question: searchText,
              answer: err,
            },
          ]);
        }
      } finally {
        setQuestionString(null);
        setSearchLoading(false);
        setSearchText('');
      }
    } else {
      setSearchLoading(false);
    }
  };

  const clearChat = () => {
    setConversation([]);
    setSearchText('');
    setClearLoading(false);
    setSearchLoading(false);
    setError('')
  };

  const clearTopicHistory = async () => {
    let localIndex: number = 1;
    setClearLoading(true);
    try {
      await clearMemoryApi();
      const dbIndex = localStorage.getItem('currentDBIndex');
      if (dbIndex) {
        if (chatId) {
          storeData(
            chatId,
            conversation,
            selectedChatName,
            selectedChatDetails,
          );
        } else {
          storeData(
            Number(dbIndex),
            conversation,
            `Chat ${dbIndex}`,
            selectedChatDetails,
            );
          localStorage.setItem('currentDBIndex', `${Number(dbIndex) + 1}`);
        }
        localIndex = Number(dbIndex);
      } else {
        storeData(1, conversation, 'Chat 1', selectedChatDetails);
        localStorage.setItem('currentDBIndex', '2');
        localIndex = 1;
      }
      setChatState([{ id: localIndex }, ...chatState]);
      clearChat();
      // Below code is to close the Side Navigation manually
      const sideNavElement = document.querySelector('.dentsu-frame--isOpen');
      if (!sideNavElement) {
        const elem: any = document.getElementsByClassName(
          'Framestyles__StyledFrameToggleButton-sc-ebhmw4-2 ',
        )[0];
        elem.click();
      }
      // history.push('/');
    } catch (e) {
      Toast()({
        title: 'Something went wrong',
        status: 'error',
      });
      setClearLoading(false);
    }
  };

  return (
    <Stack
      style={{
        flexDirection: 'column',
        height: '100%',
      }}>
      {/* Heading and Stop search Stack */}
      <ChatHeader
        chatId={chatId}
        error={error}
        setChatState={setChatState}
        clearLoading={clearLoading}
        conversation={conversation}
        chatName={selectedChatName}
        searchLoading={searchLoading}
        clearTopicHistory={clearTopicHistory}
        chatType={selectedChatDetails.chatType}
        segmentName={selectedChatDetails.segmentName}
      />
      <Scrollable
        callbacks={{
          onUpdated: () => {
            scrollRefContent?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
            scrollRefLoader?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            });
          },
        }}
        width="100%"
        height="100%">
        <Spacer height={24} />
        <Stack flexDirection="column" p="0px 134px">
          {/* Below code is for chat logs */}
          <ChatLogs
            scrollRef={scrollRefContent}
            topic={conversation}
            questionString={questionString}
            chatType={selectedChatDetails.chatType}
          />
          {/* Loading spinner and loading text stack */}
          {searchLoading && (
            <Stack padding="24px 0px">
              <img
                ref={scrollRefLoader}
                src="./dentsu_loader.gif"
                alt="loading..."
              />
            </Stack>
          )}
        </Stack>
      </Scrollable>
      <SearchBar
        error={error}
        question={searchText}
        setQuestion={setSearchText}
        getAIResponse={getAIResponse}
        searchLoading={searchLoading}
        clearLoading={clearLoading}
      />
    </Stack>
  );
};

export default ChatInterface;
