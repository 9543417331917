import React, { FC, useState } from 'react';
import {
  Page,
  Paragraph,
  EmptyState,
  Loading,
} from '@dentsu-ui/components';
import { IHomePage } from '../../utils/interfaces';
import CreateNewChatModal from '../../components/CreateNewChatModal';
import DeleteChatModal from '../../components/DeleteChatModal';
import EditChatNameModal from '../../components/EditChatNameModal';
import ChatTable from './components/ChatTable';

const HomePage: FC<IHomePage> = ({
  setIdData,
  doesChatExist,
  chatData,
  setChatState,
  isLoading,
}) => {
  if (isLoading) return <Loading />;
  const [isNewChatModalOpen, setNewChatModalOpen] = useState<boolean>(false);
  const [selectedChatData, setSelectedChatData] = useState<any>(null);
  const [deleteChatModalOpen, setDeleteChatModalOpen] =
    useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  return (
    <>
      <Page
        title="Copilot AI"
        primaryAction={{
          content: 'Create new chat',
          icon: 'add',
          onClick: () => setNewChatModalOpen(true),
          isDisabled: false,
        }}>
        {!doesChatExist ? (
          <EmptyState
            layout="horizontal"
            size="medium"
            image="create"
            title="You don't have any chats yet"
            actions={{
              secondary: {
                label: 'Create a new chat',
                onClick: () => setNewChatModalOpen(true),
                isExternal: true,
                iconLeft: 'add',
                iconRight: null,
                isLoading: false,
              },
            }}>
            <Paragraph>
              Creating a chat can quickly and easily help you find information
              from historical dentsu pitch documents, as well as the best advice
              from the wider market.
            </Paragraph>
          </EmptyState>
        ) : (
          <ChatTable
            chatData={chatData}
            setEditModalOpen={setEditModalOpen}
            setDeleteChatModalOpen={setDeleteChatModalOpen}
            setSelectedChatData={setSelectedChatData}
            setIdData={setIdData}
          />
        )}
      </Page>
      {isNewChatModalOpen && (
        <CreateNewChatModal
          isNewChatModalOpen={isNewChatModalOpen}
          setNewChatModalOpen={setNewChatModalOpen}
          setIdData={setIdData}
        />
      )}
      {editModalOpen && (
        <EditChatNameModal
          isOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          setChatState={setChatState}
          chatId={selectedChatData.id}
          chatName={selectedChatData.chatName}
        />
      )}
      {deleteChatModalOpen && (
        <DeleteChatModal
          isOpen={deleteChatModalOpen}
          chatId={selectedChatData.id}
          setChatState={setChatState}
          setDialogOpen={setDeleteChatModalOpen}
        />
      )}
    </>
  );
};

export default HomePage;
