import React, { FC } from 'react';
import { Dialog, Button } from '@dentsu-ui/components';
import { deleteData } from '../../libs/IndexDB';
import { IDeleteChatProps } from '../../utils/interfaces';

const DeleteChatModal: FC<IDeleteChatProps> = ({
  isOpen,
  setDialogOpen,
  chatId,
  setChatState,
  history,
}) => {
  const handleDeleteChat = () => {
    if (chatId !== 0) {
      deleteData(chatId);
      setDialogOpen(!isOpen);
      setChatState([chatId]);
      history && history.push('/');
    }
  };

  return (
    <Dialog
      status="warning"
      title="Are you sure you wish to delete the selected chat/s"
      isOpen={isOpen}
      onClose={() => setDialogOpen(false)}>
      <Dialog.Body>
        <p>
          Once deleted, you will no longer be able to access this information.
        </p>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          variant="secondary"
          onClick={() => setDialogOpen(false)}>
          No, cancel
        </Button>
        <Button onClick={() => handleDeleteChat()}>Yes, continue</Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default DeleteChatModal;
