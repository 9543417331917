import React from 'react';
import {
  Box,
  Stack,
  Spacer,
  Paragraph,
  IconButton,
} from '@dentsu-ui/components';
import styled from 'styled-components';
import { constants } from '../../../../libs/constants';
import { downloadXlsx } from '../../../../helpers/helpers';

const AnswerStack = styled(Stack)`
  width: 100%;
  border-radius: 4px;
  padding: 11px 16px;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  background: var(--dentsu-color-n0);
  border: 1px solid var(--border-form-n-300, #ceced6);
`;

const QuestionStack = styled(Stack)`
  border-radius: 4px;
  align-items: center;
  padding: 11px 16px;
  background: var(--dentsu-color-n200);
  border: 1px solid var(--border-form-n-300, #ceced6);
`;

const ChatLogs = (props: any) => {
  const { topic, questionString, scrollRef, chatType } = props;
  const { DENTSU_INSIGHTS } = constants;

  const renderResponse = (answerString: string) => {
    const formatMessage =
      answerString.charAt(0).toUpperCase() +
      answerString.slice(1, answerString.length);
    const responseArray = formatMessage.split('\n');
    return responseArray.map((item) => <Paragraph>{item}</Paragraph>);
  };

  return (
    <Box>
      {/* Below block of code displays full conversation after forecast API (Dentsu Insights) response */}
      {Object.keys(topic).length > 0 && chatType === DENTSU_INSIGHTS
        ? topic.map((conversation: any) => (
            <>
              {/* Question Stack */}
              <QuestionStack>
                <Paragraph>{conversation?.question}</Paragraph>
              </QuestionStack>
              <Spacer height={24} />
              {/* Answer Stack */}
              <Stack>
                <img
                  src="/dentsu_loader_static60px.png"
                  alt="DC"
                  width={60}
                  height={60}
                />
                <AnswerStack>
                  {renderResponse(conversation?.answer?.answer.trim())}
                  {/* <Spacer height={10} />
                    <Stack alignItems="center">
                      <Paragraph isSecondary>
                        {conversation.answer.updatedAt}
                      </Paragraph>
                    </Stack> */}
                </AnswerStack>
              </Stack>
              <Spacer ref={scrollRef} height={24} />
            </>
          ))
        : Object.keys(topic).length > 0 &&
          topic.map((conversation: any) => (
            <>
              {/* Question Stack */}
              <QuestionStack>
                <Paragraph>{conversation?.question}</Paragraph>
              </QuestionStack>
              <Spacer height={24} />
              {/* Answer Stack */}
              <Stack>
                <img
                  src="/dentsu_loader_static60px.png"
                  alt="DC"
                  width={60}
                  height={60}
                />
                {conversation.answer.error ? (
                  <AnswerStack>
                    {renderResponse(conversation?.answer?.error.trim())}
                    {/* <Spacer height={10} />
                    <Stack alignItems="center">
                      <Paragraph isSecondary>
                        {conversation.answer.updatedAt}
                      </Paragraph>
                    </Stack> */}
                  </AnswerStack>
                ) : (
                  <AnswerStack>
                    <Paragraph isBold>Analysis:</Paragraph>
                    <Paragraph>{conversation?.answer?.analysis}</Paragraph>
                    <Stack width="100%">
                      <img
                        src={conversation?.answer?.chart?.url}
                        alt="Chart"
                        style={{ maxWidth: '100%' }}
                      />
                    </Stack>
                    <Spacer height={10} />
                    {/* Below stack is for Date and 3 button icon on Answer stack */}
                    {/* <Stack justifyContent="space-between"> */}
                    {/* <Stack alignItems="center">
                        <Paragraph isSecondary>
                          {conversation.answer.updatedAt}
                        </Paragraph>
                      </Stack> */}
                    <Stack
                      flexDirection="row"
                      style={{ justifyContent: 'end' }}>
                      <IconButton
                        variant="ghost"
                        icon="thumbs-up"
                        title="Like"
                        isDisabled
                        onClick={() => console.log('Clicked like button!')}
                      />
                      <IconButton
                        variant="ghost"
                        icon="duplicate"
                        title="Copy text"
                        isDisabled
                        onClick={() => console.log('Clicked copy button!')}
                      />
                      <IconButton
                        variant="ghost"
                        icon="export"
                        title="Export answer"
                        onClick={() => downloadXlsx(conversation?.answer)}
                      />
                    </Stack>
                    {/* </Stack> */}
                  </AnswerStack>
                )}
              </Stack>
              <Spacer ref={scrollRef} height={24} />
            </>
          ))}
      {/**
       *  Below block of code displays question string while search is being done from API
       * After search complete setQuestionString is set to null
       */}
      {questionString?.length && (
        <QuestionStack>
          <Paragraph>{questionString}</Paragraph>
        </QuestionStack>
      )}
    </Box>
  );
};

export default ChatLogs;
