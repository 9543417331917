import { formatDate } from "../helpers/helpers";

export const openDatabase = () => {
  const indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;

  if (!indexedDB) {
    console.log("IndexedDB could not be found in this browser.");
    throw new Error(`Unable to connect to database`);
  }

  // Param 1: Database Version
  // Param 2: DB Version
  const request = indexedDB.open("dentsuChat", 1);

  request.onerror = (event) => {
    console.error(event);
    throw new Error(`An error occurred while connecting with IndexedDB`);
  };

  return request;
}

export const storeData = (chatId, requestData, chatName, chatDetails) => {
  // Open Datbase
  const request = openDatabase();

  request.onupgradeneeded = () => {
    const db = request.result;
    const createStore = db.createObjectStore("chats", { keyPath: "id", autoIncrement: true });
    createStore.createIndex("id", "id", { unique: true});
  };

  request.onsuccess = () => {
  
    const db = request.result;
    const transaction = db.transaction("chats", "readwrite");
    const store = transaction.objectStore("chats");
    
    // Find and Update chat data
    const idQuery = store.get(chatId);

    idQuery.onsuccess = () => {
      if (!idQuery.result) {
        store.put({ askQueries: requestData, lastUpdated: formatDate(new Date()) , chatName, chatDetails});
      } else {
        store.put({ ...idQuery.result, askQueries: requestData, lastUpdated: formatDate(new Date())});
      }
    };
  
    transaction.oncomplete = () => {
      db.close();
    };
  };

}

export const updateData = (chatId, chatName) => {
  // Open Datbase
  const request = openDatabase();

  request.onupgradeneeded = () => {
    const db = request.result;
    const createStore = db.createObjectStore("chats", { keyPath: "id", autoIncrement: true });
    createStore.createIndex("id", "id", { unique: true});
  };

  request.onsuccess = () => {
  
    const db = request.result;
    const transaction = db.transaction("chats", "readwrite");
    const store = transaction.objectStore("chats");
    
    // Find and Update chat data
    const idQuery = store.get(chatId);

    idQuery.onsuccess = () => {
      if (idQuery.result) {
        store.put({ ...idQuery.result, lastUpdated: formatDate(new Date()), chatName})
      }
    };
  
    transaction.oncomplete = () => {
      db.close();
    };
  };

}

export const getData = (chatId) => {
  // Open Datbase
  const request = openDatabase();

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("chats", "readonly");
      const store = transaction.objectStore("chats");
      
      // Find and Update chat data
      const idQuery = store.get(chatId);
      idQuery.onsuccess = () => {
        resolve(idQuery.result);
      }

      idQuery.onerror = (err) => reject(err);
  
      transaction.oncomplete = () => {
        db.close();
      };
    };
  });
}

export const deleteData = (chatId) => {
  // Open Datbase
  const request = openDatabase();

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("chats", "readwrite");
      const store = transaction.objectStore("chats");
      
      // Find and Delete chat data
      const idQuery = store.delete(chatId);
      idQuery.onsuccess = () => {
        resolve(idQuery.result);
      }

      idQuery.onerror = (err) => reject(err);
  
      transaction.oncomplete = () => {
        db.close();
      };
    };
  });
}

export const getAllData = () => {
  // Open Datbase
  const request = openDatabase();

  request.onupgradeneeded = () => {
    const db = request.result;
    const createStore = db.createObjectStore("chats", { keyPath: "id", autoIncrement: true });
    createStore.createIndex("id", "id", { unique: true});
  };

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("chats", "readonly");
      const store = transaction.objectStore("chats");
      
      const idQuery = store.getAll();
      idQuery.onsuccess = () => {
        if (!idQuery.result) {
          resolve([]);
        }
        resolve(idQuery.result);
      }

      idQuery.onerror = (err) => reject(err);
  
      transaction.oncomplete = () => {
        db.close();
      };
    };
  });
}
