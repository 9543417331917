import React, { useState, useEffect } from 'react';
import { Frame } from '@dentsu-ui/components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import HomePage from './modules/HomePage';
import ChatInterface from './modules/ChatInterface';
import SideNavigation from './modules/SideNavigation';
import ErrorBoundary from './modules/ErrorBoundary';
import PageNotFound from './modules/PageNotFound';
import { getAllData } from './libs/IndexDB';

const Routes = () => {
  // Below useState is used to keep updating chat list on Side navigation
  const [chatState, setChatState] = useState([]);
  const [idData, setIdData] = useState(0);
  const [indexDBData, setIndexDBData] = useState([]);
  const [doesChatExist, setDoesChatExist] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadItems = async () => {
    const data = await getAllData();
    setIsLoading(false);
    if (data.length) {
      setChatData(data);
      const items = data.map((item) => ({
        label: item.chatName,
        icon: 'chat',
        exact: true,
        url: `/copilot/${item.chatName.replaceAll(' ', '')}`,
        onClick: (e) => {
          if (!e.target.classList.contains('selected')) {
            setConversation([])
            setIdData(item.id);
          }
        },
      }));
      setDoesChatExist(true);
      setIndexDBData(items);
    } else {
      setDoesChatExist(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, [chatState]);

  return (
    <Router>
      <Frame
        navigation={
          <SideNavigation
            indexDBData={indexDBData}
            doesChatExist={doesChatExist}
          />
        }>
        <ErrorBoundary>
          <Switch>
            <Route exact path="/">
              <HomePage
                setIdData={setIdData}
                doesChatExist={doesChatExist}
                chatData={chatData}
                setChatState={setChatState}
                isLoading={isLoading}
              />
            </Route>
            <Route exact path="/copilot">
              <ChatInterface
                chatId={0}
                chatState={chatState}
                conversation={conversation}
                setChatState={setChatState}
                setConversation={setConversation}
              />
            </Route>
            <Route exact path="/copilot/:value">
              <ChatInterface
                chatId={idData}
                chatState={chatState}
                conversation={conversation}
                setChatState={setChatState}
                setConversation={setConversation}
              />
            </Route>
            <Route exact path="/pageNotFound">
              <PageNotFound />
            </Route>
            <Redirect to="/pageNotFound" />
          </Switch>
        </ErrorBoundary>
      </Frame>
    </Router>
  );
};

export default Routes;
