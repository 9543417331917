import React, { FC, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Paragraph,
  RadioGroup,
  Spacer,
  Select,
} from '@dentsu-ui/components';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ICreateNewChatModal } from '../../utils/interfaces';
import { constants } from '../../libs/constants';
import { ccsGetSegmentsApi } from '../../api';

const CustomRadioGroup: any = styled(RadioGroup)`
  .kbujJO {
    padding: 16px 28px 16px 0px !important;
  }
`;

const CreateNewChatModal: FC<ICreateNewChatModal> = (props) => {
  const {
    isNewChatModalOpen,
    setNewChatModalOpen,
    setIdData,
    clearTopicHistory,
  } = props;
  const history: any = useHistory();
  const { DENTSU_INSIGHTS, MARKETING_INTELLIGENCE } = constants;
  const [chatType, setChatType] = useState('');
  const [segmentValue, setSegmentValue] = useState(null);
  const [segmentOptions, setSegmentOptions] = useState({});

  useEffect(() => {
    ccsGetSegmentsApi().then((response: any) => {
      const segmentArray: any = [];
      response.segments.forEach((item: any) =>
        segmentArray.push({ value: item.name, label: item.description }),
      );
      setSegmentOptions(segmentArray);
    });
  }, []);

  const chatOptions = [
    {
      label: 'Dentsu Insights',
      value: DENTSU_INSIGHTS,
      description: 'Access insights from our CCS survey',
      render: () => (
        <Select
          menuPosition='fixed'
          placeholder="Please select an audience segment"
          options={segmentOptions}
          onChange={(selected) => {
            setSegmentValue(selected);
          }}
          value={segmentValue}
        />
      ),
    },
    {
      label: 'Marketing Intelligence',
      value: MARKETING_INTELLIGENCE,
      description: 'Access insights from latest market intelligence models',
    },
  ];

  return (
    <Modal
      isOpen={isNewChatModalOpen}
      onClose={() => setNewChatModalOpen(false)}>
      <Modal.Header
        hasCloseButton
        title="Select a chat type"
        metadata="Before you start"
      />
      <Modal.Body>
        <Paragraph>What type of information are you looking for?</Paragraph>
        <Spacer />
        <CustomRadioGroup
          name="chatTypes"
          selectedValue={chatType}
          options={chatOptions}
          onChange={(e) => {
            setChatType(e.target.value);
            setSegmentValue(null);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginRight: 'initial' }}
          variant="secondary"
          onClick={() => setNewChatModalOpen(false)}>
          Cancel
        </Button>
        <Button
          isDisabled={
            !chatType.length || (chatType === DENTSU_INSIGHTS && !segmentValue)
          }
          onClick={() => {
            if (clearTopicHistory) {
              clearTopicHistory();
            } else {
              setIdData && setIdData(0);
            }
            setNewChatModalOpen(false);
            history.push({
              pathname: '/copilot',
              state: { chatType, segmentValue },
            });
          }}>
          Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateNewChatModal;
