import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Page, Layout, EmptyState, Paragraph,
} from '@dentsu-ui/components';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  render() {
    const { error, hasError } = this.state;
    const { history, children } = this.props;
    if (hasError) {
      return (
        <Page title="Error">
          <Layout>
            <Layout.Section>
              <Layout.Panel>
                <EmptyState
                  data-test="errorBoundar-emptyState"
                  layout="horizontal"
                  size="medium"
                  image="void"
                  actions={{
                    secondary: {
                      label: 'Home',
                      onClick: () => {
                        history.push('/');
                      },
                      isDisabled: false,
                      isExternal: true,
                      iconLeft: 'home',
                      iconRight: null,
                      isLoading: false,
                    },
                  }}
                  title="Something went wrong!"
                >
                  <Paragraph>
                    {error && error.toString()}
                  </Paragraph>
                </EmptyState>
                <br />
              </Layout.Panel>
            </Layout.Section>
          </Layout>
        </Page>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ErrorBoundary);
